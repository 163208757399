.no-scroll {
  overflow-y: hidden !important;
}

#__next {
  display: contents;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: underline;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

svg.icon {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.seo-discriminator {
  visibility: hidden;
}

.onpage-anchor {
}

.brand_logo_origin {
  display: none;
}

#nprogress {
  pointer-events: none;
}

.small-icon img {
  max-width: 40px;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #191919 !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice),
.klaro .cookie-modal {
  z-index: 100010 !important; /* Place above ProvenExpert bottom bar */
  border-radius: 7px !important;
  height: 100%;
  outline: none !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  max-height: 410px;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  justify-content: center;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body > p {
  overflow-y: auto;
}

.klaro .cm-btn {
  border-radius: 7px !important;
  min-width: 100px;
  height: auto !important;
}

.klaro .cm-link {
  color: #FF9900 !important;
}

.klaro .cm-btn.cm-btn-success,
.klaro .cm-btn.cm-btn-success-var {
  background: #FF9900 !important;
}

@media only screen and (max-width: 600px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    position: fixed !important;
    /*top: 20px !important;*/
    right: 20px !important;
    bottom: 20px !important;
    left: 20px !important;
    width: calc(100% - 40px) !important;
  }

  .klaro .cookie-notice:not(.cookie-modal-notice),
  .klaro .cookie-modal {

  }

  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

div[data-type="placeholder"][data-name="youtube"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div[data-type="placeholder"][data-name="youtube"] .klaro.cm-as-context-notice {
  padding: 0;
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #FF9900 !important;
  background: rgba(255, 255, 255, 0.35);
  border-radius: 4px;
}

.klaro .cookie-modal a::before,
.klaro .cookie-modal a::after,
.klaro .context-notice a::before,
.klaro .context-notice a::after,
.klaro .cookie-notice a::before,
.klaro .cookie-notice a::after {
  content: "";
  width: 4px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cm-service .purposes {
  display: none !important;
}

.klaro .cm-list-description {
  width: 100%;
  overflow-x: auto;
}

.klaro table.service-details {
  border: none;
  border-spacing: 0;
}

.klaro table.service-details tr {
}

.klaro table.service-details td:first-child {
  white-space: nowrap;
  width: 1px;
  font-weight: 700;
  padding: 0;
  padding-right: 7px;
  vertical-align: top;
}

.klaro.cm-as-context-notice .cm-buttons .cm-btn:first-child {
  display: none !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  width: 100% !important;
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
}

.klaro .klaro-brand-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.klaro .klaro-brand-logo img {
  display: block;
  width: 150px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .klaro .cookie-modal .cm-modal .cm-footer-buttons {
    flex-flow: column !important;
  }

  .klaro .cookie-modal .cm-modal .cm-footer-buttons .cm-btn {
    margin-bottom: 15px;
  }
}
