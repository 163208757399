.no-scroll {
  overflow-y: hidden !important;
}

#__next {
  display: contents;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: underline;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

svg.icon {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.seo-discriminator {
  visibility: hidden;
}

.onpage-anchor {
}

.brand_logo_origin {
  display: none;
}

#nprogress {
  pointer-events: none;
}

.small-icon img {
  max-width: 40px;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #191919 !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice),
.klaro .cookie-modal {
  z-index: 100010 !important; /* Place above ProvenExpert bottom bar */
  border-radius: 7px !important;
  height: 100%;
  outline: none !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  max-height: 410px;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  justify-content: center;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body > p {
  overflow-y: auto;
}

.klaro .cm-btn {
  border-radius: 7px !important;
  min-width: 100px;
  height: auto !important;
}

.klaro .cm-link {
  color: #FF9900 !important;
}

.klaro .cm-btn.cm-btn-success,
.klaro .cm-btn.cm-btn-success-var {
  background: #FF9900 !important;
}

@media only screen and (max-width: 600px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    position: fixed !important;
    /*top: 20px !important;*/
    right: 20px !important;
    bottom: 20px !important;
    left: 20px !important;
    width: calc(100% - 40px) !important;
  }

  .klaro .cookie-notice:not(.cookie-modal-notice),
  .klaro .cookie-modal {

  }

  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

div[data-type="placeholder"][data-name="youtube"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div[data-type="placeholder"][data-name="youtube"] .klaro.cm-as-context-notice {
  padding: 0;
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #FF9900 !important;
  background: rgba(255, 255, 255, 0.35);
  border-radius: 4px;
}

.klaro .cookie-modal a::before,
.klaro .cookie-modal a::after,
.klaro .context-notice a::before,
.klaro .context-notice a::after,
.klaro .cookie-notice a::before,
.klaro .cookie-notice a::after {
  content: "";
  width: 4px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #FF9900 !important;
}

.klaro .cm-service .purposes {
  display: none !important;
}

.klaro .cm-list-description {
  width: 100%;
  overflow-x: auto;
}

.klaro table.service-details {
  border: none;
  border-spacing: 0;
}

.klaro table.service-details tr {
}

.klaro table.service-details td:first-child {
  white-space: nowrap;
  width: 1px;
  font-weight: 700;
  padding: 0;
  padding-right: 7px;
  vertical-align: top;
}

.klaro.cm-as-context-notice .cm-buttons .cm-btn:first-child {
  display: none !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  width: 100% !important;
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
}

.klaro .klaro-brand-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.klaro .klaro-brand-logo img {
  display: block;
  width: 150px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .klaro .cookie-modal .cm-modal .cm-footer-buttons {
    flex-flow: column !important;
  }

  .klaro .cookie-modal .cm-modal .cm-footer-buttons .cm-btn {
    margin-bottom: 15px;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Mulish Fallback';src: local("Arial");ascent-override: 96.56%;descent-override: 24.02%;line-gap-override: 0.00%;size-adjust: 104.08%
}.__className_6f2494 {font-family: 'Mulish', 'Mulish Fallback';font-style: normal
}

